.card {
  padding: 0;
  border-radius: var(--border-radius-3);
  background: rgba(var(--card-rgb), 0.1);
  border: 1px solid rgba(var(--card-border-rgb), 0.15);
  transition: background 200ms, border 200ms;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  animation: zoomIn 400ms;
}
.card:hover {
  border-color: var(--color-primary);
}

@keyframes zoomIn {
  0% {
    scale: 0.7;
  }
  100% {
    scale: 1;
  }
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.14);
  }
  .card:hover span {
    transition: var(--transition);
    transform: translate(4px);
  }
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--color-white);
}

.card .picture {
  height: 250px;
  overflow: hidden;
}

.card .card__details {
  flex: 1;
  text-align: left;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card .card__details .card__details__top {
  border: none;
}

.card .card__details .card__details__middle {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(var(--card-border-rgb), 0.15);
  height: 160px;
  overflow-x: hidden;
}

.card .card__details .card__details__middle .description {
  font-size: 14px;
  color: var(--color-muted);
  max-height: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .card__details .card__details__middle .description.expanded {
  max-height: none;
}

.card .see-more {
  color: var(--color-primary);
  cursor: pointer;
  margin-top: 0.5rem;
}
.card .see-more:hover {
  color: var(--color-yellow);
}

/* Stack */
.card .stack__container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: .5rem;
}

.card .stack__container .stack__left {
  display: flex;
  align-items: center;
  border: 0.1px solid rgba(var(--card-border-rgb), 0.15);
  border-radius: var(--border-radius-2);
  background: rgba(var(--card-rgb), 0.1);
  padding: 0.1rem;
}

.card .stack__container .stack__right {
  flex: 1;
}

.card .stack__container .stack__right .stack__box__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
#stack__BOX{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
}
.stack__name {
  font-size: 15px;
  font-weight: 500;
  margin-bottom:0;
}

.card .stack__box__container .stack__box .stack__icon__container:hover {
  background: var(--color-ui-1);
}

.card .stack__container .stack__right .stack__box {
  border: 1px solid rgba(var(--card-border-rgb), 0.15);
  border-radius: var(--border-radius-2);
  padding: 0.5rem;
  background: rgba(var(--card-rgb), 0.1);
}

.card .stack__container .stack__right .stack__box:hover {
  background: var(--color-ui-2);
}

.card .stack__box__container .stack__box .stack__icon__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  padding: 0.5rem;
  border-radius: var(--border-radius-2);
  background: rgba(var(--card-rgb), 0.05);
}

.card .stack__box__container .stack__box .stack__icon__container .stack__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.card .stack__container .stack__right .stack__box .stack__view__more {
  border: 1px solid rgba(var(--card-border-rgb), 0.15);
  border-radius: var(--border-radius-2);
  padding: 0.5rem;
  background: rgba(var(--card-rgb), 0.1);
}

.card .stack__view__more {
  width: 100%;
  padding: 1rem 0.5rem;
  margin-top: 1rem;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.card .stack__view__more .more__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
}

.card .stack__view__more .more__btn:hover {
  color: var(--color-yellow);
  border-radius: 50px;
  width: 50%;
  margin: 0 auto;
}

.card .stack__view__more .stack__expand__box {
  position: absolute;
  bottom: 100%;
  background: var(--color-ui-2);
  border: 1px solid rgba(var(--card-border-rgb), 0.15);
  border-radius: var(--border-radius-2);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: none;
}

.card .stack__view__more .open__stack__expand__box {
  display: block;
}

.card .stack__view__more .stack__expand__box:hover {
  background: var(--color-ui-2);
}

.card .stack__view__more .stack__expand__box .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.card .button__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .1rem;
}

/* Media Queries */
@media (max-width: 1200px) {
  .card .title {
    font-size: 15px;
  }
  .card .picture {
    height: 220px;
  }
  .card .card__details .card__details__middle {
    height: 140px;
  }
}

@media (max-width: 992px) {
  .card .title {
    font-size: 14px;
  }
  .card .picture {
    height: 200px;
  }
  .card .card__details .card__details__middle {
    height: 120px;
  }
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .card .title {
    font-size: 13px;
  }
  .card .picture {
    height: 180px;
  }
  .card .card__details .card__details__middle {
    height: 100px;
  }
  .card .stack__container {
    flex-direction: column;
  }
  .card .stack__container .stack__right .stack__box {
    padding: 0.25rem;
  }
}

@media (max-width: 576px) {
  .card .title {
    font-size: 12px;
  }
  .card .picture {
    height: 140px;
  }
  .card .card__details .card__details__middle {
    height: 150px;
  }
  .card .stack__container {
    flex-direction: column;
    gap: 0.5rem;
    height: 10rem;
  }
  .card .stack__container .stack__left {
    display: none;
  }
  .card .stack__container .stack__right .stack__box {
    padding: 0.1rem;
    height: 60px;
  }
  .card .stack__view__more {
    padding: 0.1rem;
    color: var(--color-white);
  }
  .card .stack__view__more .open__stack__expand__box {
    padding: 1rem 0.5rem;
  }
}
