/* Google Fonts Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --color-primary: #2196f3;
  --color-secondary: rgba(76, 175, 80, 0.8);
  --color-text: #e0e0e0;
  --color-muted: #757575;
  --color-success: #4caf50;
  --color-white: #e0e0e0;
  --color-ui-1: rgba(25, 25, 25);
  --color-ui-2: rgba(50, 50, 50);
  --color-off-white: #303030;
  --transition: all 400ms ease;
  --gray-border: solid 0.5px #424242;
  --border-radius-1: 8px;
  --border-radius-2: 1.2rem;
  --border-radius-3: 1.8rem;
  --border-radius-4: 2.8rem;
  --border-radius-5: 64px;
  --gradient-1: linear-gradient(
    260deg,
    var(--color-secondary) 0%,
    var(--color-primary) 100%
  );
  --card-rgb: 55, 55, 55;
  --card-border-rgb: 33, 33, 33;
  --color-bg-variant: #546e7a;
  --color-lightblue: #03a9f4;
  --color-yellow: #ffeb3b;
}

* {
  margin: 0;
  padding: 0.5px;
  scrollbar-color: var(--color-primary) var(--color-secondary);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scrollbar-width: thin;
  list-style: none;
  border: 0;
  outline: 0;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: var(--gradient-1);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

body {
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  background: var(--color-ui-1);
  color: var(--color-text);
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

img {
  min-height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn {
  padding: 12px 20px;
  border-radius: var(--border-radius-5);
  color: var(--color-text);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  background: var(--color-ui-2);
  text-align: center;
  cursor: pointer;
}

.btn__primary {
  background: var(--gradient-1);
  color: var(--color-white);
  background-size: 200% auto;
  transition: var(--transition);
}

.btn__primary:hover {
  background-position: right center;
}

.color__primary {
  color: var(--color-primary);
}

.text__muted {
  color: var(--color-muted);
  font-size: 21px;
}
.text__muted__capstone {
  color: var(--color-muted);
  font-size: 21px;
}

.stroke__text {
  -webkit-text-stroke: 8px rgba(55, 55, 55, 0.2);
  -webkit-text-fill-color: transparent;
}

.primary__title {
  font-size: 48px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-primary) 0%,
    var(--color-white) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary__title__2 {
  font-size: 48px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary__title__3 {
  font-size: 20px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__wrapper {
  max-width: 1190px;
  margin: auto;
  padding: 60px 20px;
}

.icon__container {
  width: 2.5rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-5);
  cursor: pointer;
  transition: var(--transition);
  color: var(--color-muted);
}

.icon__container:hover {
  background: var(--color-ui-2);
  transform: scale(1.2);
  color: var(--color-white);
}

.blur-effect {
  position: relative;
}

.blur-effect::before {
  content: "";
  position: absolute;
  width: 322px;
  height: 308px;
  background: var(--color-primary);
  background: linear-gradient(
    190deg,
    var(--color-primary) 0%,
    rgba(33, 150, 243, 0) 100%
  );
  -webkit-filter: blur(150px);
  filter: blur(150px);
  z-index: -1;
}

.section__header {
  width: 100%;
  margin-bottom: 20px;
}

.section__header.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.section__header.center .primary__title,
.section__header.center .description {
  text-align: center;
}

.section__header.center .description {
  max-width: 900px;
}
.text__muted__capstone,
.text__muted__certification,
.text__muted__contact {
  font-size: 16px;
  color: var(--color-muted);
}

/* Responsive */

@media (max-width: 1100px) {
  .primary__title {
    font-size: 32px;
  }
}

@media (max-width: 950px) {
  .section__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .section__header.center .primary__title,
  .section__header.center .title {
    text-align: center;
  }
  .section__header .description {
    margin: auto;
    text-align: center;
  }
  .text__muted__capstone {
    font-size: 12px;
  }
  .text__muted__certification {
    font-size: 13px;
    color: var(--color-muted);
  }
  .text__muted__contact {
    font-size: 14px;
    color: var(--color-muted);
  }
  .blur-effect::before {
    display: none;
  }
  body{
    margin: .1px;
    padding: .7px;
  }
}

/* React Odometer.js --style */

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  /* *vertical-align: auto;
  *zoom: 1;
  *display: inline; */
  position: relative;
  font-size: 60px;
  font-weight: 900;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  /* *vertical-align: auto;
  *zoom: 1;
  *display: inline; */
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  /* *vertical-align: auto;
  *zoom: 1;
  *display: inline; */
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-default
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}
.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

@media (max-width: 1100px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-default {
    font-size: 32px;
  }
}
@media (max-width: 500px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-default {
    font-size: 20px;
  }
}

/* Capstone Section Specific Styles */
.portfolio__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 2rem;
}

.my__capstone {
  color: var(--gradient-1);
}

.title__4 {
  font-size: 15px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.primary__title__2 {
  font-size: 55px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0.1rem;
  margin-top: 0;
}

.title__3 {
  font-size: 20px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.portfolio__group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding: 0;
  margin: 0;
}

.portfolio__item {
  background: var(--color-ui-1);
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.portfolio__item:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.portfolio__item-image {
  width: 100%;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio__item-content {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin: 0;
}

.portfolio__item h3 {
  margin: 1.2rem 0;
  color: #a63d40;
}

.portfolio__item small {
  margin-bottom: 1.5rem;
  display: block;
}

@media screen and (max-width: 1024px) {
  .portfolio__group {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__group {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.see-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.see-more-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.see-more-btn:hover {
  background-color: var(--color-primary-dark);
}
