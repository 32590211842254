/* Keyframes for bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Keyframes for drawer animation */
@keyframes drawer {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

#navigation__link {
  position: relative;
  transition: color 0.3s;
}

#navigation__link:hover {
  color: var(--primary-color);
}

#navigation__link:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--gradient-1);
  border-radius: var(--border-radius-4);
  animation: drawer 2s ease infinite;
}

/* Footer container */
#footer .section__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

#footer .nav__link__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

#footer .social__handles__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

#footer .social__handles {
  width: 3rem;
  font-size: 1.5rem;
  transition: color 0.3s;
}

#footer .social__handles:hover {
  color: var(--primary-color);
}

#footer .copyright__container {
  text-align: center;
  margin-top: 1.5rem;
}

.scroll__up {
  display: flex;
  font-size: 1rem;
  padding: 0.5rem;
  color: var(--color-primary);
  align-items: center;
  border-radius: 5rem;
  border: 1px solid var(--color-secondary);
  width: max-content;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.scroll__up:hover {
  background-color: var(--color-primary);
  color: var(--color-light);
  animation: bounce 1.5s ease infinite;
}
.tab__item__footer {
  position: relative;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-text);
}

@media (max-width: 768px) {
  #footer .nav__link__container {
    flex-direction: column;
    gap: .1px;
    margin:0;
  }
  #footer .copyright__container {
    margin-top: .2rem;
    margin-bottom: .8rem;
    font-size: 13px;
    color: var(--color-muted);
  }
}
