/* Portfolio Container */
.portfolio__container {
  display: grid;
  gap: 2rem;
  padding: 2rem 0;
}

/* Remove any unnecessary padding or margin */
.container-wrapper {
  position: relative;
  padding: 0;
  margin: 0;
}

.my__capstone {
  color: var(--gradient-1);
}

.title__4 {
  font-size: 15px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.primary__title__2 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
}

.title__3 {
  font-size: 20px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

/* Portfolio Group */
.portfolio__group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding: 0;
  margin: 0;
}

/* Portfolio Item */
.portfolio__item {
  background: var(--color-ui-1);
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin: 0;
}

.portfolio__item:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

/* Portfolio Item Image */
.portfolio__item-image {
  width: 100%;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Portfolio Item Content */
.portfolio__item-content {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin: 0;
}

.portfolio__item h3 {
  margin: 1.2rem 0;
  color: #a63d40;
}

.portfolio__item small {
  margin-bottom: 1.5rem;
  display: block;
}

/* Media Queries for Large Screens */
@media screen and (max-width: 1024px) {
  .portfolio__group {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
}

/* Media Queries for Small Screens */
@media screen and (max-width: 600px) {
  .portfolio__group {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.see-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.see-more-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.see-more-btn:hover {
  color: var(--color-yellow);
}
