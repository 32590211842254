#certification .section__wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 25% 75%;
}

#certification .certification__container {
  width: 100%;
  padding-bottom: 4rem !important;
}

#certification .client__avatar {
  width: 10rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 20%;
  margin: 0 auto 1rem;
  border: var(--gray-border);
}

#certification .certification {
  background: var(--color-ui-2);
  text-align: center;
  padding: 20px;
  border-radius: var(--border-radius-4);
  user-select: none;
}

.client__name {
  font-size: 16px;
  font-weight: 900;
  background: linear-gradient(
    to right,
    var(--color-lightblue) 0%,
    var(--color-yellow) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#certification .client__detail__container {
  position: relative;
  height: 6rem;
  overflow: hidden;
  transition: height 0.3s ease;
}

#certification .client__detail {
  color: var(--color-text);
  font-weight: 300;
  font-size: 15px;
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.scroll__arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  color: var(--color-primary);
  font-size: 1.2rem;
  cursor: pointer;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

@media (max-width: 950px) {
  #certification .section__wrapper {
    grid-template-columns: 100%;
  }
  #certification .client__avatar {
    width: 20rem;
    height: 15rem;
  }
}
