#header {
  overflow: hidden;
  position: relative;
}

#header .header__container {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
}

#header .intro__text {
  position: absolute;
  top: 120px;
  left: 0;
  font-size: 200px;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 0;
}

#header .column {
  z-index: 1;
  position: relative;
}

#header .intro__container {
  width: 550px;
}

#header .header__info__top {
  font-size: 15px;
  font-weight: 500;
}

#header .header__title {
  font-size: 62px;
  font-weight: 900;
}

#header .header__description {
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0;
}

#header .header__info__bottom {
  display: flex;
  gap: 30px;
  margin-top: 40px;
  align-items: center;
}

#header .profile__wrapper {
  flex: 1;
}

#header .profile__photo__container {
  width: 500px;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  /* animation: pulse-and-fade 10s ease infinite; */
}

#header .profile__photo__container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(transparent, var(--color-ui-1));
}

#resume__class {
  background: linear-gradient(
    260deg,
    var(--color-secondary) 0%,
    var(--color-primary) 100%
  );
  background-size: 200% auto;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
  border: 2px solid var(--color-secondary);
  color: white;
  cursor: pointer;
  transition: background-position 0.5s ease-in-out, color 0.3s;
}

#resume__class:hover {
  background-position: right center;
  color: var(--color-yellow);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  animation: pulse 3s ease backwards, pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(217, 155, 155, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(124, 223, 198, 0);
  }
}

#resume__class:active {
  animation: pulse 0.6s;
}

/* Social Media Icons */
.header__socials {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.header__socials a {
  font-size: 2.1rem;
  transition: all 1s;
  animation: fadeIn 3.5s ease forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-45px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.header__socials a:hover {
  animation: pulse 3s ease backwards, bounce 1.4s ease infinite;
  color: var(--color-yellow);
}

@media (max-width: 1100px) {
  #header .header__title {
    font-size: 32px;
  }
}

@media (max-width: 850px) {
  #header {
    min-height: auto;
  }

  #header__letter {
    font-size: 0.1px;
    color: rgb(185, 183, 183);
  }

  #header .header__container {
    flex-direction: column;
    align-items: center;
  }

  #header .intro__text {
    font-size: 100px;
  }

  #header .intro__container {
    width: 100%;
  }
  #header .profile__photo__container {
    width: 350px;
    height: 320px;
    border: 3px solid var(--color-off-white);
    border-radius: 20% 20%;
    /* animation: slide-and-glow  8s ease infinite; */
  }
  #header .profile__photo__container:hover {
    border: 1px solid var(--color-muted);
  }

  .header__title,
  .header__info__top,
  .header__description {
    text-align: center;
  }

  .header__info__bottom {
    justify-content: center;
    gap: 20px;
  }

  .header__info__bottom .btn {
    width: 25%;
    padding: 15px;
  }
}

@keyframes spin-and-zoom {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes pulse-and-fade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes tilt-and-shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes flip-and-fade {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(360deg);
    opacity: 1;
  }
}

@keyframes slide-and-glow {
  0% {
    transform: translateX(0);
    box-shadow: 0 0 10px var(--color-off-white);
  }
  25% {
    transform: translateX(10px);
    box-shadow: 0 0 20px var(--color-off-white);
  }
  50% {
    transform: translateX(0);
    box-shadow: 0 0 10px var(--color-off-white);
  }
  75% {
    transform: translateX(-10px);
    box-shadow: 0 0 20px var(--color-off-white);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 10px var(--color-off-white);
  }
}