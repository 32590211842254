#skill .skill__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  #skill .skill__container {
    grid-template-columns: 1fr;
  }
  #skill .skill__container .skill__card {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  #skill .skill__container {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  #skill .skill__container {
    gap: 1rem;
  }
  #skill .skill__container .skill__card {
    padding: 2rem 1rem;
    width: 100%;
  }
}

.section__wrapper {
  padding-top: 0;
}

.section__header {
  margin-bottom: 0;
}

.primary__title__2 {
  margin-top: 0;
}
