#contact .contact__container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#contact .section__header {
  flex: 1;
}

#contact .contact__group {
  display: grid;
  grid-template-columns: 45% 50%;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  width: 65%;
}

#contact .contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

#contact .contact__option {
  border-radius: var(--border-radius-3);
  background: rgba(var(--color-primary-rgb), 0.1);
  border:.5px solid var(--color-primary);
  transition: background 200ms, border 200ms;
  padding: 1.2rem;
  text-align: center;
}

#contact .contact__option:hover {
  background: transparent;
  border:3px solid var(--color-primary);
}

#contact .contact__icon {
  font-size: 25px;
  margin-bottom: 0.5rem;
}

#contact .contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 12px;
}

#contact form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: var(--border-radius-3);
  background: rgba(var(--color-primary), 0.1);
  border:1px solid var(--color-primary);
  resize: none;
  color: var(--color-white);
}

input:focus,
textarea:focus {
  border-color: var(--color-primary);
}

/* Media Queries for better responsiveness */
@media (max-width: 1024px) {
  #contact .contact__group {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  #contact .contact__group {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  #contact .contact__container {
    flex-direction: column;
    align-items: center;
  }

  #contact .contact__group {
    width: 80%;
    gap: 1rem;
  }

  #contact .contact__option {
    padding: .5rem;
  }

  input,
  textarea {
    padding: 1rem;
  }
}
