.skill__card {
  padding: 30px 40px;
  border: 1px solid transparent;
  transition: var(--transition);
  border-radius: var(--border-radius-3);
  background: rgba(var(--card-rgb), 0.1);
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.skill__card::before,
.skill__card::after {
  content: "";
  position: absolute;
}

.skill__card::before {
  width: 200%;
  height: 200%;
  background-image: conic-gradient(
    var(--color-primary),
    transparent 60deg,
    transparent 180deg,
    var(--color-primary),
    transparent 240deg
  );
  inset: -60%;
  z-index: -2;
  /* animation: spin 8s linear infinite; */
  animation-play-state: paused;
}
.skill__card:hover {
  border-color: var(--color-ui-2);
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

.skill__card:hover::before {
  animation-play-state: running;
}

.skill__card::after {
  background: var(--color-ui-2);
  inset: 0.25rem;
  border-radius: calc(1.2rem - 0.25rem);
  z-index: -1;
  transition: var(--transition);
}

.skill__card:hover {
  cursor: default;
}

.skill__card h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.skill__card .skill__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.skill__card .skill__details {
  display: flex;
  gap: 1rem;
}

.skill__card .skill__icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.skill__card .skill__details .skill__name {
  font-size: 15px;
  font-weight: 500;
}

.skill__card .skill__details .skill__level {
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .skill__card .skill__details .skill__name {
    font-size: 12px;
  }
  .skill__card .skill__details .skill__level {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .skill__card .skill__content {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .skill__card h3 {
    font-size: 18px;
  }
  .skill__card .skill__details {
    gap: 0.2rem;
    font-size: 13px;
  }
  .skill__card .skill__details .skill__level {
    font-size: 11px;
    justify-content: center;
    text-align: center;
  }
}
