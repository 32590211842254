.fact__container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.fact__container .fact__item{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.fact__container .count__container{
    display: flex;
    align-items: center;
}

.fact__container .indicator{
    font-size: 60px;
    font-weight: 900;
}

.fact__container .name{
    color: var(--color-off-white);
    font-size: 14px;
    text-align: center;
}

@media (max-width:1100px) {
    .fact__container .indicator{
        font-size: 32px
    }
    .fact__container .name{
        font-size: 12px;
    }
}

@media (max-width:500px) {

    .fact__container{
        margin-left: 30%;
        color: var(--color-muted);
    }

    .fact__container .indicator{
        font-size: 20px;
        margin:0;
        
    }
    .fact__container .name{
        font-size: 10px;
        margin:0;
    }
}