.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1px;
  position: relative;
  width: 100%;
}

/* Scroll  */
.navbar__container.visible {
  background: var(--color-ui-2);
  position: sticky;
}

.navbar__container .logo__container {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  cursor: pointer;
  font-size: 35px;
}

.navbar__container .tab__group {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
}

.navbar__container .tab__item {
  position: relative;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  color: var(--gradient-1);
}

.navbar__container .tab__item:active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--gradient-1);
  border-radius: var(--border-radius-4);
  animation: drawer 0.5s;
}
.navbar__container .tab__item:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--gradient-1);
  border-radius: var(--border-radius-4);
  animation: drawer 2.2s ease infinite;
}

@keyframes drawer {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.navbar__container .nav__buttons__group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar__container .menu {
  font-size: 25px;
  cursor: pointer;
  display: none;
}

.navbar__container .close__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: 16px;
  display: none;
}

.navbar__container .overlay {
  position: fixed;
  background: rgba(1, 1, 1, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  z-index: 199;
  user-select: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .navbar__container {
    background: var(--color-ui-2);
  }
  .navbar__container .tab__group {
    position: fixed;
    width: 300px;
    height: 100%;
    min-height: 100vh;
    background: var(--color-ui-1);
    top: 0;
    left: -100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 200;
    transition: var(--transition);
    padding-top: 20px;
  }
  .navbar__container .tab__group.show {
    left: 0;
  }
  .navbar__container .menu {
    display: block;
  }
  .navbar__container .close__btn {
    display: flex;
  }
}
